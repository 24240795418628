import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import HeaderSEO from '../components/partials/HeaderSEO'
import HomePage from '../hanzo/pages/home'
import { GoogleTag } from '../components/google-tag'

const RootIndex: React.FC = () => {
    const data = useStaticQuery(graphql`
        query LensesHanzoHomeSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Homepage" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Fragment>
            <Layout newsletter={true} linkColor={'dark'} isTransparent={true}>
                <HeaderSEO pageSEO={pageSEO} />
                <GoogleTag />
                <HomePage />
            </Layout>
        </Fragment>
    )
}

export default RootIndex
